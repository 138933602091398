import { Pathnames, LocalePrefix } from 'next-intl/routing';

// Locale default untuk fallback jika locale tidak ditemukan
export const defaultLocale = 'id' as const;

// Daftar locale yang didukung
export const locales = ['en', 'id', 'zh', 'es', 'ar'] as const;

// Type untuk pathnames
type PathConfig = {
  [key: string]: {
    en: string;
    id: string;
    zh: string;
    es: string;
    ar: string;
  };
};

// Type untuk path labels
type PathLabels = {
  [K in keyof typeof pathLabels]: {
    zh: string;
    ar: string;
  };
};

// Pathnames untuk routing berdasarkan locale
export const pathnames: PathConfig = {
  '/': {
    en: '/',
    id: '/',
    zh: '/',
    es: '/',
    ar: '/',
  },
  '/services': {
    en: '/services',
    id: '/layanan',
    zh: '/services',
    es: '/servicios',
    ar: '/services',
  },
  '/service-details': {
    en: '/services',
    id: '/layanan',
    zh: '/services',
    es: '/servicios',
    ar: '/services',
  },
  '/services/[slug]': {
    en: '/services/[slug]',
    id: '/layanan/[slug]',
    zh: '/services/[slug]',
    es: '/servicios/[slug]',
    ar: '/services/[slug]',
  },
  '/pricing': {
    en: '/pricing',
    id: '/harga',
    zh: '/pricing',
    es: '/precios',
    ar: '/pricing',
  },
  '/about': {
    en: '/about',
    id: '/tentang',
    zh: '/about',
    es: '/acerca-de',
    ar: '/about',
  },
  '/projects': {
    en: '/projects',
    id: '/portofolio',
    zh: '/projects',
    es: '/proyectos',
    ar: '/projects',
  },
  '/projects/[slug]': {
    en: '/projects/[slug]',
    id: '/portofolio/[slug]',
    zh: '/projects/[slug]',
    es: '/proyectos/[slug]',
    ar: '/projects/[slug]',
  },
  '/search': {
    en: '/search',
    id: '/cari',
    zh: '/search',
    es: '/buscar',
    ar: '/search',
  },
  '/search/[searchTerm]': {
    en: '/search/[searchTerm]',
    id: '/cari/[searchTerm]',
    zh: '/search/[searchTerm]',
    es: '/buscar/[searchTerm]',
    ar: '/search/[searchTerm]',
  },
  '/posts': {
    en: '/posts',
    id: '/blog',
    zh: '/posts',
    es: '/articulos',
    ar: '/posts',
  },
  '/posts/[slug]': {
    en: '/posts/[slug]',
    id: '/blog/[slug]',
    zh: '/posts/[slug]',
    es: '/articulos/[slug]',
    ar: '/posts/[slug]',
  },
  '/contact': {
    en: '/contact',
    id: '/kontak',
    zh: '/contact',
    es: '/contacto',
    ar: '/contact',
  },
  '/get-a-quote': {
    en: '/get-a-quote',
    id: '/minta-penawaran',
    zh: '/get-a-quote',
    es: '/obtener-cotizacion',
    ar: '/get-a-quote',
  },
  '/whatsapp-link-generator': {
    en: '/whatsapp-link-generator',
    id: '/pembuat-link-whatsapp',
    zh: '/whatsapp-link-generator',
    es: '/generador-enlace-whatsapp',
    ar: '/whatsapp-link-generator',
  },
  '/business-name-generator': {
    en: '/business-name-generator',
    id: '/ai-pembuat-nama-bisnis',
    zh: '/business-name-generator',
    es: '/generador-nombres-negocios',
    ar: '/business-name-generator',
  },
  '/tos': {
    en: '/tos',
    id: '/persyaratan-layanan',
    zh: '/tos',
    es: '/terminos',
    ar: '/tos',
  },
  '/privacy': {
    en: '/privacy',
    id: '/kebijakan-privasi',
    zh: '/privacy',
    es: '/privacidad',
    ar: '/privacy',
  },
  '/refund-policy': {
    en: '/refund-policy',
    id: '/kebijakan-pengembalian-dana',
    zh: '/refund-policy',
    es: '/politica-de-reembolso',
    ar: '/refund-policy',
  },
} as const;

// Mapping label untuk tampilan UI dalam bahasa asli
export const pathLabels = {
  'services': {
    zh: '服务',
    ar: 'الخدمات',
  },
  'pricing': {
    zh: '定价',
    ar: 'التسعير',
  },
  'about': {
    zh: '关于',
    ar: 'من-نحن',
  },
  'projects': {
    zh: '项目',
    ar: 'المشاريع',
  },
  'search': {
    zh: '搜索',
    ar: 'بحث',
  },
  'posts': {
    zh: '文章',
    ar: 'المقالات',
  },
  'contact': {
    zh: '联系',
    ar: 'الاتصال',
  },
  'get-a-quote': {
    zh: '获取报价',
    ar: 'احصل-على-عرض-سعر',
  },
  'whatsapp-link-generator': {
    zh: '创建WhatsApp链接',
    ar: 'مولد-رابط-واتساب',
  },
  'business-name-generator': {
    zh: '生成企业名称',
    ar: 'مولد-اسماء-الشركات',
  },
  'tos': {
    zh: '服务条款',
    ar: 'الشروط',
  },
  'privacy': {
    zh: '隐私政策',
    ar: 'الخصوصية',
  },
  'refund-policy': {
    zh: '退款政策',
    ar: 'سياسة-الاسترداد',
  },
} as const;

// Helper function untuk mendapatkan label dalam bahasa asli
export const getLocalizedPathLabel = (path: string, locale: string): string => {
  // Hapus leading slash jika ada
  const cleanPath = path.startsWith('/') ? path.slice(1) : path;
  
  // Jika ada slug, ambil bagian pertama saja
  const basePath = cleanPath.split('/')[0] as keyof typeof pathLabels;
  
  if (pathLabels[basePath] && (locale === 'zh' || locale === 'ar')) {
    return pathLabels[basePath][locale];
  }

  // Fallback ke path biasa
  const localePath = pathnames[`/${basePath}`];
  if (localePath && typeof localePath === 'object') {
    const localized = localePath[locale as keyof typeof localePath];
    return localized?.replace('/', '') || basePath;
  }

  return basePath;
};

// Prefiks locale (selalu disertakan dalam URL)
export const localePrefix: LocalePrefix = 'always';

// Port dan Host untuk pengembangan dan produksi
export const port = process.env.PORT || 3000;
export const host = process.env.VERCEL_URL
  ? `https://${process.env.VERCEL_URL}`
  : `http://localhost:${port}`;

// Fungsi validasi untuk mengecek apakah locale valid
export const isValidLocale = (locale: string): boolean => {
  return locales.includes(locale as typeof locales[number]);
};

// Helper function untuk mendapatkan base URL WordPress berdasarkan locale
export const getWPBaseUrl = (locale: string): string => {
  const baseUrl = process.env.NEXT_PUBLIC_WORDPRESS_URL || 'https://blog.mubarokah.com';
  return `${baseUrl}${locale !== defaultLocale ? '/' + locale : ''}`;
};

