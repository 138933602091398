'use client';

import { useState, useEffect } from 'react';
import { useLocale } from 'next-intl';
import { useRouter } from 'next/navigation';
import { locales } from '@/config';
import Image from 'next/image';
import * as Dialog from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { useTranslations } from 'next-intl';
import Cookies from 'js-cookie';

interface LanguagePair {
  left: string | null;
  right: string | null;
}

const createLanguagePairs = (locales: string[]): LanguagePair[] => {
  const pairs: LanguagePair[] = [];
  for (let i = 0; i < locales.length; i += 2) {
    pairs.push({
      left: locales[i],
      right: i + 1 < locales.length ? locales[i + 1] : null,
    });
  }
  return pairs;
};

export default function LocaleSwitcher() {
  const t = useTranslations('LocaleSwitcher');
  const router = useRouter();
  const locale = useLocale();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  // Dapatkan path saat ini pada client-side
  useEffect(() => {
    // Get current path without locale prefix
    const pathname = window.location.pathname;
    const pathWithoutLocale = pathname.replace(new RegExp(`^/(${locales.join('|')})`), '');
    setCurrentPath(pathWithoutLocale || '/');
  }, []);

  const languagePairs = createLanguagePairs([...locales]);

  const handleLocaleChange = (selectedLocale: string) => {
    // Simpan preferensi bahasa di cookie
    Cookies.set('NEXT_LOCALE', selectedLocale, { expires: 365, path: '/' });
    
    // Alihkan ke halaman yang sama dengan bahasa yang baru
    const newPath = `/${selectedLocale}${currentPath === '/' ? '' : currentPath}`;
    router.push(newPath);
    setIsOpen(false);
  };

  const LanguageButton = ({ locale: loc }: { locale: string }) => (
    <button
      onClick={() => handleLocaleChange(loc)}
      className={`flex items-center w-full p-4 transition-colors hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg ${
        loc === locale ? 'bg-gray-100 dark:bg-gray-700' : ''
      }`}
    >
      <Image
        src={`/flags/${loc}.svg`}
        alt={loc}
        width={24}
        height={24}
        className="mr-3"
      />
      <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
        {loc.toUpperCase()}
      </span>
      {loc === locale && (
        <span className="ml-auto text-green-500">✓</span>
      )}
    </button>
  );

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>
        <button className="flex items-center bg-white border border-gray-300 rounded-md px-4 py-2 text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors">
          <Image
            src={`/flags/${locale}.svg`}
            alt={locale}
            width={20}
            height={20}
            className="mr-2"
          />
          {locale.toUpperCase()}
          <span className="ml-2">⌄</span>
        </button>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/40 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 z-[9999]" />
        <Dialog.Content className="fixed left-[50%] top-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-lg bg-white dark:bg-gray-900 p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] z-[10000]">
          <Dialog.Title className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">
            {t('label')}
          </Dialog.Title>

          <div className="overflow-y-auto max-h-[60vh] pr-6 -mr-6">
            <div className="grid grid-cols-2 gap-2">
              {languagePairs.map((pair, index) => (
                <div key={index} className="contents">
                  {pair.left && (
                    <div className="col-span-1">
                      <LanguageButton locale={pair.left} />
                    </div>
                  )}
                  {pair.right && (
                    <div className="col-span-1">
                      <LanguageButton locale={pair.right} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <Dialog.Close asChild>
            <button
              className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-white dark:ring-offset-gray-900 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-gray-950 dark:focus:ring-gray-100 focus:ring-offset-2 disabled:pointer-events-none"
              aria-label="Close"
            >
              <X className="h-4 w-4" />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}