"use client";

import * as React from "react";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import SiteLogo from "./site-logo";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Phone, Email, Address } from "@/components/icons/icons";
import { useTranslations } from "next-intl";

export function Offcanvas() {
  const t = useTranslations("Offcanvas");
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <button className="group ml-2 flex h-8 w-8 items-center justify-center rounded-full transition-colors lg:ml-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="h-6 w-6 fill-foreground transition-colors group-hover:fill-primary group-focus:fill-primary dark:fill-white"
          >
            <path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z"></path>
          </svg>
          <span className="sr-only">Toggle Menu</span>
        </button>
      </SheetTrigger>
      <SheetContent side="right" className="pl-1 pr-0">
        <ScrollArea className="mb-4 mt-16 h-[calc(100vh-8rem)] px-16 pb-10">
          <div className="pl-1 pr-7">
            <Link href="/" className="shrink-0">
              <SiteLogo
                width={123}
                height={39}
                lightClasses="dark:hidden"
                darkClasses="hidden dark:block"
              />
            </Link>
            <p className="mb-8 mt-6">
              {t("desc")}
            </p>
            <div className="mb-12 flex space-x-2">
              <a
                href="https://x.com/mubarokah_id/"
                className="mb-2 flex h-10 w-10 items-center justify-center rounded bg-black text-white transition-colors hover:bg-foreground hover:text-white"
              >
                <FontAwesomeIcon icon={faXTwitter} width={15} />
              </a>
              <a
                href="https://www.facebook.com/mubarokah.co.id/"
                className="mb-2 flex h-10 w-10 items-center justify-center rounded bg-[#324e8c] text-white transition-colors hover:bg-foreground hover:text-white"
              >
                <FontAwesomeIcon icon={faFacebook} width={15} />
              </a>
              <a
                href="https://www.instagram.com/mubarokah.co.id/"
                className="mb-2 flex h-10 w-10 items-center justify-center rounded bg-[#FF0062] text-white transition-colors hover:bg-foreground hover:text-white"
              >
                <FontAwesomeIcon icon={faInstagram} width={15} />
              </a>
              <a
                href="https://www.linkedin.com/company/mubarokah/"
                className="mb-2 flex h-10 w-10 items-center justify-center rounded bg-[#0A66C2] text-white transition-colors hover:bg-foreground hover:text-white"
              >
                <FontAwesomeIcon icon={faLinkedin} width={15} />
              </a>
            </div>
            <h2 className="text-sm">{t("contact")}</h2>
            <ul className="space-y-5 text-sm">
              <li className="flex space-x-4">
                <Phone />
                <a
                  href="https://wa.me/62811143352?text=Hello!%20I%20am%20interested%20in%20the%20services%20offered%20by%20*Mubarokah*."
                  className="hover:text-primary"
                >
                  +62 811-1433-52
                </a>
              </li>
              <li className="flex space-x-4">
                <Email />
                <a
                  href="mailto:hey@mubarokah.com"
                  className="hover:text-primary"
                >
                  hey@mubarokah.com
                </a>
              </li>
              <li className="flex space-x-4">
                <Address />
                <address className="-mt-px leading-6">
                  {t("address")}
                </address>
              </li>
            </ul>
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
}
