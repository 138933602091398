// Base configuration
export const siteConfig = {
  url: "https://mubarokah.com/",
  ogImage: "https://mubarokah-landing-page.vercel.app/GraphImageWebMubarokah.webp",
  name: "Mubarokah: Digital Creative Agency",
  WP_REST_BASE_URL: "https://blog.mubarokah.com/wp-json/wp/v2",
  keywords: [
    "web development",
    "mobile app development",
    "digital marketing",
    "UI/UX design",
    "branding",
    "e-commerce development",
    "content marketing",
    "SEO",
    "social media marketing",
    "data analytics",
    "custom web development services",
    "mobile app development for startups",
    "digital marketing strategy for small businesses",
    "responsive web design",
    "user experience design",
    "search engine optimization services",
    "social media advertising campaigns",
    "data-driven marketing solutions",
    "healthcare app development",
    "e-commerce website design",
    "fintech solutions",
    "innovative digital solutions",
    "scalable web applications",
    "results-driven marketing campaigns",
    "Digital Agency",
    "offshore development team",
  ],
};

export const getWPBaseUrl = (locale: string): string => {
  if (locale === 'id') {
    return "https://blog.mubarokah.com/id/wp-json/wp/v2";
  }
  return "https://blog.mubarokah.com/wp-json/wp/v2"; // Default untuk bahasa Inggris
};

// Konfigurasi yang perlu diterjemahkan
export const getTranslatedConfig = (messages: any) => ({
  name: messages?.Metadata?.name || "Mubarokah: Digital Creative Agency",
  description: messages?.Metadata?.description || "Your go-to digital creative agency",
  keywords: messages?.Metadata?.keywords?.split(',').map((k: string) => k.trim()) || siteConfig.keywords,
});

// Interface untuk data sebelum diterjemahkan
export interface BaseNavItem {
  titleKey: string;
  href?: string;
  items?: BaseNavItem[];
}

// Interface untuk data yang sudah diterjemahkan
export interface TranslatedNavItem {
  title: string;
  titleKey: string;
  href?: string;
  items?: TranslatedNavItem[];
}

// Interface untuk item navigasi utama
export interface MainNavItem extends BaseNavItem {
  titleKey: string;
  href?: string;
  items?: BaseNavItem[];
}

// Interface untuk item footer
export interface FooterItem {
  titleKey: string;
  items: {
    titleKey: string;
    href: string;
    external: boolean;
  }[];
}

export const WP_REST_BASE_URL = "https://blog.mubarokah.com/wp-json/wp/v2";

// Menu navigasi utama dengan kunci terjemahan
export const mainNav: MainNavItem[] = [
  {
    titleKey: "Navigation.home",
    href: "/",
  },
  {
    titleKey: "Navigation.services",
    href: "/services/",
  },
  {
    titleKey: "Navigation.pricing",
    href: "/pricing/",
  },
  {
    titleKey: "Navigation.pages",
    items: [
      {
        titleKey: "Navigation.about",
        href: "/about/",
        items: [],
      },
      {
        titleKey: "Navigation.projects",
        href: "/projects/",
        items: [],
      },
      {
        titleKey: "Navigation.blog",
        href: "/posts/",
        items: [],
      },
      {
        titleKey: "Navigation.contact",
        href: "/contact/",
        items: [],
      },
      {
        titleKey: "Navigation.munaAssistant",
        href: "/muna-assistant/",
        items: [],
      },
      {
        titleKey: "Navigation.ourProducts",
        href: "https://mudin.myr.id/",
        items: [],
      },
    ],
  },
  {
    titleKey: "Navigation.tools",
    items: [
      {
        titleKey: "Navigation.whatsappGenerator",
        href: "/whatsapp-link-generator/",
        items: [],
      },
      {
        titleKey: "Navigation.businessNameGenerator",
        href: "/business-name-generator/",
        items: [],
      },
      {
        titleKey: "Navigation.tiktokDownloader",
        href: "https://tiktok-downloader.mubarokah.com/",
        items: [],
      },
      {
        titleKey: "Navigation.instagramDownloader",
        href: "https://instagram-downloader.mubarokah.com/",
        items: [],
      },
      {
        titleKey: "Navigation.klikMagic",
        href: "https://remove-bg.mubarokah.com/",
        items: [],
      },
    ],
  },
];

// Footer navigasi dengan kunci terjemahan
export const footerNav2: FooterItem[] = [
  {
    titleKey: "Footer.solutions",
    items: [
      {
        titleKey: "Footer.webDevelopment",
        href: "/services/web-design-development/",
        external: false,
      },
      {
        titleKey: "Footer.digitalMarketing",
        href: "/services/digital-marketing/",
        external: false,
      },
      {
        titleKey: "Footer.contentCreator",
        href: "/services/content-creator/",
        external: false,
      },
      {
        titleKey: "Footer.MobileAppDevelopment",
        href: "/services/mobile-app-development/",
        external: false,
      },
      {
        titleKey: "Footer.UI/UXDesigner",
        href: "/services/ui-ux-designer/",
        external: false,
      },
      {
        titleKey: "Footer.GraphicDesigner",
        href: "/services/graphics-designer/",
        external: false,
      },
      {
        titleKey: "Footer.SEOSpecialist",
        href: "/services/seo/",
        external: false,
      },
      {
        titleKey: "Footer.HostingVPSCloud",
        href: "/services/hosting/",
        external: false,
      },
      {
        titleKey: "Footer.WhatsAppBusinessAPI",
        href: "/services/whatsapp-business-api/",
        external: false,
      },
    ],
  },
  {
    titleKey: "Footer.quickLinks",
    items: [
      {
        titleKey: "Footer.contact",
        href: "/contact/",
        external: false,
      },
      {
        titleKey: "Footer.WhatsAppLinkGenerator",
        href: "/whatsapp-link-generator/",
        external: false,
      },
      {
        titleKey: "Footer.BusinessNameGenerator",
        href: "/business-name-generator/",
        external: false,
      },
      {
        titleKey: "Footer.TikTokDownloader",
        href: "https://tiktok-downloader.mubarokah.com/",
        external: true,
      },
      {
        titleKey: "Footer.InstagramDownloader",
        href: "https://instagram-downloader.mubarokah.com/",
        external: true,
      },
      {
        titleKey: "Footer.KlikMagic",
        href: "https://remove-bg.mubarokah.com/",
        external: true,
      },
      {
        titleKey: "Footer.SystemStatus",
        href: "https://mdn.cronitorstatus.com/",
        external: true,
      },
      {
        titleKey: "Footer.OurProducts",
        href: "https://mudin.myr.id/",
        external: true,
      },
    ],
  },
  {
    titleKey: "Footer.company",
    items: [
      {
        titleKey: "Footer.aboutCompany",
        href: "/about/",
        external: false,
      },
      {
        titleKey: "Footer.Blog",
        href: "/posts/",
        external: false,
      },
      {
        titleKey: "Footer.TermsofService",
        href: "/tos/",
        external: false,
      },
      {
        titleKey: "Footer.PrivacyPolicy",
        href: "/privacy/",
        external: false,
      },
      {
        titleKey: "Footer.RefundPolicy",
        href: "/refund-policy/",
        external: false,
      },
      {
        titleKey: "Footer.Services",
        href: "/services/",
        external: false,
      },
      {
        titleKey: "Footer.Portfolio",
        href: "/projects/",
        external: false,
      },
      {
        titleKey: "Footer.Pricing",
        href: "/pricing/",
        external: false,
      },
      {
        titleKey: "Footer.MunaAssistant",
        href: "/muna-assistant/",
        external: false,
      },
    ],
  },
] satisfies FooterItem[];
