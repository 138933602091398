import { z } from "zod";
import isMobilePhone from "validator/lib/isMobilePhone";


export const createNewsletterFormSchema = (t: any) => z.object({
  name: z.string().optional(),
  email: z.string().email(t('errors.invalidEmail')),
});

export const createContactFormSchema = (t: any) => z.object({
  name: z.string().trim().min(1, t("zodMessage.nameReq")).max(255),
  namaperusahaan: z.string().min(1, t("zodMessage.companyNameReq")),
  jabatan: z.string().min(1, t("zodMessage.jobTitleReq")),
  email: z.string().email(t("zodMessage.emailInvalid")).min(1, t("zodMessage.emailReq")),
  message: z.string().min(12, t("zodMessage.messageReq")),
});


export const createQuotationFormSchema = (t: any) => z.object({
  name: z.string().min(1, t("schema.nameReq")).max(255),
  companyname: z.string().optional(), // Opsional
  jobtitle: z.string().optional(), // Opsional
  email: z.string().email(t("schema.emailInvalid")).min(1, t("schema.emailReq")),
  phone: z.string().refine(isMobilePhone, t("schema.phoneInvalid")),
  website: z.string().optional().refine((value) => !value || value.startsWith(t("http")) || value.startsWith(t("https")), {
    message: t("schema.urlInvalid"),
  }), // Opsional dan hanya divalidasi jika diisi
  service: z.string().min(1, t("schema.serviceReq")),
  message: z.string().min(1, t("schema.messageReq")),
});


export const SearchFormSchema = z.object({
  query: z.string().optional(),
});
